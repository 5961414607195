import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Segment, { SegmentConfig } from "@sparefoot/segment-react";

import { Input } from "components/core/Input";
import {
	createAutocomplete,
	formatPlaceObject
} from "utils/search/autocomplete";

export default function GoogleAutocomplete({
	className,
	icon,
	id,
	label,
	name,
	onChange,
	placeholder,
	value,
	borderlessInput,
	segmentConfig,
	segmentLabel,
	segmentCategory = "search"
}) {
	const [autoCompleteInitialized, setAutoCompleteInitialized] =
		useState(false);
	const searchInputRef = useRef(null);

	const trackAutoComplete = (place) => {
		const segmentProps = SegmentConfig.buildTrackingProps(segmentConfig, {
			segmentLabel: "search autocomplete",
			segmentProperties: { extra: { place } },
			segmentCategory
		});
		Segment.trackClick(segmentProps);
	};

	const handleUserInputChange = ({ target }) => {
		// handles user input events after blur
		// but NOT google autocomplete changes
		const targetValue = target.value;
		onChange({ location: targetValue });
	};

	const handleAutoCompleteChange = (val, place) => {
		// fires when the user hits RETURN on an auto complete value
		// also fires when the user CLICKS an auto complete option

		// when google autocomplete changes the input's value, that doesn't
		// trigger a `change` event, only an `input` event. This means we have
		// to artificially apply this change.

		onChange({ ...formatPlaceObject(place), location: val });
		trackAutoComplete(place);
	};

	const handleFocus = () => {
		if (!autoCompleteInitialized && searchInputRef.current) {
			createAutocomplete(
				searchInputRef.current,
				handleAutoCompleteChange
			).then((autocompleteResponse) => {
				// this logs the response from the google integration
				// we save it in state to know not to reinitialize it.
				if (autocompleteResponse) {
					setAutoCompleteInitialized(true);
				}
			});
		}
	};

	return (
		<Input
			autoComplete="off"
			className={className}
			icon={icon}
			id={id}
			label={label}
			name={name}
			placeholder={placeholder}
			type="text"
			segmentLabel={segmentLabel}
			value={value}
			onChange={handleUserInputChange}
			onFocus={handleFocus}
			getRef={searchInputRef}
			borderless={borderlessInput}
		/>
	);
}

GoogleAutocomplete.propTypes = {
	onChange: PropTypes.func,
	name: PropTypes.string,
	value: PropTypes.string,
	label: PropTypes.string,
	icon: PropTypes.node,
	id: PropTypes.string,
	segmentLabel: PropTypes.string,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	segmentConfig: PropTypes.object,
	borderlessInput: PropTypes.bool,
	segmentCategory: PropTypes.string
};
