import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Form } from "@sparefoot/react-commons";
import { Button } from "components/core/Button";
import { MapPinFilled, LoadingBoxes } from "components/core/Icons";
import { GoogleAutocomplete } from "components/core/GoogleAutocomplete";
import buildSearchLink from "utils/search/buildSearchLink";

import "./SearchBar.scss";

const THEMES = {
	white: {
		borderlessInput: false,
		buttonTheme: "primary",
		LoadingAnimationTheme: "blue"
	},
	lightBlue: {
		borderlessInput: true,
		buttonTheme: "secondary",
		loadingAnimationTheme: "green"
	},
	transparnet: {
		borderlessInput: false,
		buttonTheme: "primary",
		LoadingAnimationTheme: "blue"
	},
	lightBlue2: {
		borderlessInput: true,
		buttonTheme: "primary",
		loadingAnimationTheme: "blue"
	},
	lightBlue3: {
		borderlessInput: true,
		buttonTheme: "secondary",
		loadingAnimationTheme: "green"
	},
	lightBlueSmall: {
		borderlessInput: true,
		buttonTheme: "secondary",
		loadingAnimationTheme: "green"
	},
	stickyDarkBlue: {
		borderlessInput: true,
		buttonTheme: "primary",
		loadingAnimationTheme: "blue"
	},
	darkBlue: {
		borderlessInput: false,
		buttonTheme: "primary",
		LoadingAnimationTheme: "blue"
	}
};
export default function SearchBar({
	buttonText,
	id,
	location,
	segmentCategory,
	children,
	className,
	label,
	theme = "white",
	searchIn
}) {
	const [locationParams, setLocationParams] = useState({ ...location });
	const [locating, setLocating] = useState(false);
	let searchInQuery = {};
	if (searchIn) {
		searchInQuery = { searchType: "vehicle", vehicle: searchIn };
	}
	useEffect(() => {
		// patch to fix an issue w/ state being cached in browser memory
		setLocating(false);
	});

	const handleSubmit = (event) => {
		event.preventDefault();
		setLocating(true);

		const url = buildSearchLink({ ...locationParams, ...searchInQuery });

		window.location.assign(url);
	};

	const handleChange = (value) => {
		setLocationParams(value);
	};

	return (
		<Form
			className={classnames(
				"search-bar",
				className,
				`searchbar-${theme}`
			)}
			onSubmit={handleSubmit}
			successLabel="search-bar-success"
			asyncSubmit={false}
			segmentCategory={segmentCategory}
		>
			<div className="children-container">{children}</div>
			<div className={classnames("form-container", className)}>
				<GoogleAutocomplete
					id={id}
					type="text"
					label={label}
					segmentLabel="search bar"
					segmentCategory={segmentCategory}
					className="location-input"
					name="location"
					onChange={handleChange}
					value={location}
					placeholder=""
					icon={<MapPinFilled fill="blue" />}
					borderlessInput={THEMES[theme].borderlessInput}
				/>
				<Button
					theme={THEMES[theme].buttonTheme}
					type="submit"
					segmentLabel="facility search button"
					segmentCategory={segmentCategory}
					className="search-button"
					fullWidth
				>
					{locating ? (
						<LoadingBoxes
							size="huge"
							primaryColor={THEMES[theme].loadingAnimationTheme}
						/>
					) : (
						buttonText
					)}
				</Button>
			</div>
		</Form>
	);
}

SearchBar.propTypes = {
	buttonText: PropTypes.string,
	id: PropTypes.string,
	location: PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.string,
	label: PropTypes.string,
	theme: PropTypes.oneOf(Object.keys(THEMES)),
	segmentCategory: PropTypes.string,
	searchIn: PropTypes.string
};

SearchBar.defaulProps = {
	theme: "white"
};

SearchBar.preventDefault = {
	children: null
};
