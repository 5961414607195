import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Text } from "components/core/Typography";

import "./Label.scss";

export default function Label({
	children,
	className,
	htmlFor,
	icon,
	text,
	textClassName
}) {
	return (
		<label
			className={classnames("label", className)}
			htmlFor={htmlFor}
		>
			{children}
			<Text className={classnames("label-text", textClassName)}>
				{!!icon && <span className="icon">{icon}</span>}
				{text}
			</Text>
		</label>
	);
}

Label.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	htmlFor: PropTypes.string.isRequired,
	icon: PropTypes.node,
	text: PropTypes.node,
	textClassName: PropTypes.string
};
