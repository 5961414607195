import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Input as CoreInput } from "@sparefoot/react-commons";
import { Label } from "components/core/Label";
import { Paragraph } from "components/core/Typography";

import "./Input.scss";

export default function Input({
	autoComplete,
	className,
	disabled,
	error,
	getRef,
	helpText,
	icon,
	id,
	label,
	name,
	onBlur,
	onChange,
	onFocus,
	placeholder,
	readOnly,
	required,
	success,
	segmentLabel,
	type,
	valid,
	value,
	borderless
}) {
	const [isEmpty, setEmptyInput] = useState();
	const handleChange = (event) => {
		setEmptyInput(!!event.target.value);
		onChange(event);
	};
	return (
		<Label
			htmlFor={id}
			text={
				required ? (
					<Fragment>
						{label}
						<span className="required"> *</span>
					</Fragment>
				) : (
					label
				)
			}
			className={classnames({
				[`${className}-label`]: className,
				error,
				success
			})}
			icon={icon}
			textClassName="input-label"
		>
			<CoreInput
				autoComplete={autoComplete}
				className={classnames("input", className, {
					"not-empty-input": isEmpty,
					borderless
				})}
				disabled={disabled}
				getRef={getRef}
				id={id}
				name={name}
				onBlur={onBlur}
				onFocus={onFocus}
				onChange={handleChange}
				placeholder={placeholder}
				readOnly={readOnly}
				segmentLabel={segmentLabel}
				type={type}
				valid={valid}
				value={value}
			/>
			{!!helpText && (
				<Paragraph className="help-text">{helpText}</Paragraph>
			)}
			{!!error && (
				<Paragraph className="error-message">{error}</Paragraph>
			)}
		</Label>
	);
}

Input.propTypes = {
	autoComplete: PropTypes.string,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	error: PropTypes.string,
	getRef: PropTypes.object,
	helpText: PropTypes.string,
	icon: PropTypes.node,
	id: PropTypes.string,
	label: PropTypes.string,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	readOnly: PropTypes.bool,
	required: PropTypes.bool,
	segmentLabel: PropTypes.string.isRequired,
	success: PropTypes.bool,
	type: PropTypes.string,
	valid: PropTypes.bool,
	value: PropTypes.string,
	borderless: PropTypes.bool
};
